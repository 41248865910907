<template>
  <div class="">
    <!-- heading selection part  -->
    <h5 class="fw-light text-white">
      lead Steps > <span class="fw-bold text-white">index</span>
    </h5>
    <div
      class="d-flex flex-column flex-sm-row justify-content-between align-items-md-center py-5 mb-10"
    >
      <h1 class="text-white quiz-heading fw-light">
        Lead <span class="fw-bold">Quizzes</span>
      </h1>
      <div class="d-md-flex gap-6 align-items-end">
        <!-- for quiz type  -->

        <div class="mb-4 mb-md-0">
          <label class="fs-7 mb-2 text-secondary" for=""
            >Select Quiz Type
          </label>
          <select
            name=""
            id=""
            class="form-select form-select-solid group-dropdown fs-7 w-100 w-md-150px p-2"
            aria-label="Lead Steps"
          >
            <option selected disabled hidden value="">Lead Steps</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <!-- for Category  -->
        <div>
          <label class="fs-7 mb-2 text-secondary" for="">Select Category</label>
          <select
            name=""
            id=""
            class="form-select form-select-solid group-dropdown fs-7 w-100 w-md-150px p-2"
            aria-label="all"
          >
            <option selected disabled hidden value="">All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <button
          type="button"
          class="btn d-lg-block d-none btn-info justify-self-center fs-5"
        >
          New Quiz
        </button>
      </div>
    </div>
    <!-- lead quizes gallary  -->
    <div class="" style="--bs-gutter-y: 0rem; --bs-gutter-x: 0rem">
      <!-- quiz lead gallary container   -->
      <div
        class="d-flex gap-10 gap-lg- flex-wrap justify-content-center justify-content-lg-start"
      >
        <!-- quiz lead create card  -->
        <div
          class="lead-add-card w-md-300px w-lg-275px card-width rounded d-none d-md-flex flex-column align-items-center justify-content-center"
        >
          <i
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="36"
              height="36"
              fill="#999BB3"
              style="margin-top: 15px"
            >
              <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
              ></path></svg
          ></i>
          <h4 class="fw-semi-bold mt-2 fs-3">Create</h4>
          <h5 class="fs-3 fw-light">Lead Quiz</h5>
        </div>

        <!-- card  -->
        <div class="w-100 w-md-300px w-lg-275px card-width">
          <!-- image area container -->
          <div class="">
            <img src="@/assets/gallery/quiz1.png" alt="" class="image-sizing" />
            <div
              class="px-3 py-5"
              style="border-bottom: 1px solid rgb(110, 111, 118)"
            >
              <span class="fs-6 text-white fw-light">Lead Quiz Name</span>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table
                class="table table-borderless text-white fs-7 fw-light mt-3"
              >
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th scope="row">1</th> -->
                    <td>20</td>
                    <td>10</td>
                    <td>50%</td>
                    <td>5</td>
                    <td>14%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button
                  data-bs-toggle="dropdown"
                  class="btn btn-dots-manue ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-start manue-items-list rounded overflow-hidden dropdown-menu bg-black"
                >
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Deleted Lead Step
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="dropdown-divider"
                    />
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- col-sm-12 col-md-6 col-lg-6 col-xl-3 card-width -->
        <div class="w-100 w-md-300px w-lg-275px card-width">
          <!-- image area container -->
          <div class="">
            <img src="@/assets/gallery/quiz1.png" alt="" class="image-sizing" />
            <div
              class="px-3 py-5"
              style="border-bottom: 1px solid rgb(110, 111, 118)"
            >
              <span class="fs-6 text-white fw-light">Lead Quiz Name</span>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table
                class="table table-borderless text-white fs-7 fw-light mt-3"
              >
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th scope="row">1</th> -->
                    <td>20</td>
                    <td>10</td>
                    <td>50%</td>
                    <td>5</td>
                    <td>14%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button
                  data-bs-toggle="dropdown"
                  class="btn btn-dots-manue ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-start manue-items-list rounded overflow-hidden dropdown-menu bg-black"
                >
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Deleted Lead Step
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="dropdown-divider"
                    />
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 w-md-300px w-lg-275px card-width">
          <!-- image area container -->
          <div class="">
            <img src="@/assets/gallery/quiz1.png" alt="" class="image-sizing" />
            <div
              class="px-3 py-5"
              style="border-bottom: 1px solid rgb(110, 111, 118)"
            >
              <span class="fs-6 text-white fw-light">Lead Quiz Name</span>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table
                class="table table-borderless text-white fs-7 fw-light mt-3"
              >
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th scope="row">1</th> -->
                    <td>20</td>
                    <td>10</td>
                    <td>50%</td>
                    <td>5</td>
                    <td>14%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button
                  data-bs-toggle="dropdown"
                  class="btn btn-dots-manue ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </button>
                <ul
                  class="dropdown-menu manue-items-list rounded overflow-hidden dropdown-menu bg-black"
                >
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Deleted Lead Step
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="dropdown-divider"
                    />
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 w-md-300px w-lg-275px card-width">
          <!-- image area container -->
          <div class="">
            <img src="@/assets/gallery/quiz1.png" alt="" class="image-sizing" />
            <div
              class="px-3 py-5"
              style="border-bottom: 1px solid rgb(110, 111, 118)"
            >
              <span class="fs-6 text-white fw-light">Lead Quiz Name</span>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table
                class="table table-borderless text-white fs-7 fw-light mt-3"
              >
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th scope="row">1</th> -->
                    <td>20</td>
                    <td>10</td>
                    <td>50%</td>
                    <td>5</td>
                    <td>14%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  class="btn btn-dots-manue ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </button>
                <ul
                  class="dropup dropdown-menu dropdown-menu-start manue-items-list rounded overflow-hidden dropdown-menu bg-black"
                >
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Deleted Lead Step
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="dropdown-divider"
                    />
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 w-md-300px w-lg-275px card-width">
          <!-- image area container -->
          <div class="">
            <img src="@/assets/gallery/quiz1.png" alt="" class="image-sizing" />
            <div
              class="px-3 py-5"
              style="border-bottom: 1px solid rgb(110, 111, 118)"
            >
              <span class="fs-6 text-white fw-light">Lead Quiz Name</span>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table
                class="table table-borderless text-white fs-7 fw-light mt-3"
              >
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th scope="row">1</th> -->
                    <td>20</td>
                    <td>10</td>
                    <td>50%</td>
                    <td>5</td>
                    <td>14%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button
                  data-bs-toggle="dropdown"
                  class="btn btn-dots-manue ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </button>
                <ul
                  class="dropup dropdown-menu dropdown-menu-start manue-items-list rounded overflow-hidden dropdown-menu bg-black"
                >
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Deleted Lead Step
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="dropdown-divider"
                    />
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a
                      role="menuitem"
                      href="#"
                      target="_self"
                      class="dropdown-item"
                    >
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
}
</script>

<style scoped lang="scss">
.btn-dot-manu-container {
  position: relative;
  ul {
    padding: 0.4rem 0;
    margin: 0 !important;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        padding: 0.8rem 1rem;
        display: inline-block;
        color: white;
      }
      a:hover {
        color: black;
      }
    }
    hr {
      opacity: 1;
    }
    hr.dropdown-divider {
      border-top: 1px solid #fff;
    }
  }
}

.quiz-heading {
  font-size: 35px !important;
}
.btn {
  height: fit-content !important;
}
.btn-dots-manue {
  padding: 0.3rem !important;
  transform: scale(1.1);
  ul {
    position: absolute;
    margin: 0px;
    transform: translate(-2px, -36px);
    inset: auto auto 0px 0px;
  }
}

.btn-stats {
  padding: 1px !important;
  height: fit-content !important;
}
.card-width {
  min-height: 125px;

  background: #202441;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lead-add-card {
  cursor: pointer;
  background: #2a2e54;

  h4,
  h5 {
    color: #999bb3 !important;
  }
}

.image-sizing {
  object-fit: fill;
  object-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 130px;
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
}
.remove-default-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.table-content {
  height: 50px !important ;
  margin: 7px 0;
}
.table > :not(caption) > * > * {
  text-align: center;
  padding: 0.1rem 0.2rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 00px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
th {
  padding-bottom: 0.5rem !important;
}
select {
  cursor: pointer;
}
@media (min-width: 200px) and (max-width: 1300px) {
  .quiz-heading {
    font-size: 28px !important;
    padding: 1rem 0;
  }
  .lead-quiz-gallary {
    justify-content: center !important;
  }
}
@media (min-width: 200px) and (max-width: 500px) {
  ul {
    position: absolute !important;
    inset: auto 0px 0px auto !important;
    margin: 0px !important;
    transform: translate3d(14px, -33.5px, 0px) !important;
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  btn-dot-manu-container {
    ul {
      position: absolute;
      inset: auto 0px 0px auto !important;
      margin: 0px;
      transform: translate3d(14px, -33.5px, 0px) !important;
    }
  }
}

@media (min-width: 1440px) {
  .card-width {
    width: 275px !important;
  }
}
</style>
